import React, { useImperativeHandle, useState } from "react";
import "./passwordSettings.scss";
import { Container, Flex, Avatar, Text, Spinner, Box } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { apiClient } from "../../../../../config/apiClient";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../state/reducers";
import {
  CHANGE_PASSWORD_API_URL,
  ERROR_CODE,
  SUCCESS_CODE,
} from "../../../../../config/constant";
import { showErrorToast, showSuccessToast } from "../../../../ui/atoms/Toast";
import PasswordInput from "../../../../ui/atoms/formElements/PasswordInput";

const PasswordSettings = (_: any, ref: React.Ref<unknown> | undefined) => {
  const [loading, setLoading] = useState(false);
  const methods = useForm();
  const { handleSubmit, watch, reset } = methods;

  useImperativeHandle(
    ref,
    () => {
      return {
        resetForm: () => reset(),
      };
    },
    []
  );

  const user = useSelector((state: RootState) => state.user.userData);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const { emailAddress } = user ?? {};
    const { password, newPassword } = data;

    const response = await apiClient.put(
      CHANGE_PASSWORD_API_URL,
      { emailAddress, currentPassword: password, newPassword },
      {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      }
    );

    if (response?.data?.statusCode === SUCCESS_CODE) {
      showSuccessToast("Success", response?.data?.statusMessage);
      reset();
    }

    if (response?.data?.statusCode === ERROR_CODE) {
      showErrorToast("Error", response?.data?.statusMessage);
    }

    setLoading(false);
  };

  const { imageLocation } = user ?? {};
  const profileImageURL = imageLocation
    ? `${process.env.REACT_APP_IMAGE_HOST_URL}/${imageLocation}`
    : "";

  return (
    <Container
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"72vh"}
    >
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        minW={"400px"}
      >
        <Flex justifyContent={"center"} mb={"40px"}>
          <Avatar
            src={profileImageURL}
            borderRadius="20px"
            boxSize="140px"
            border={"1px solid #2c8526"}
          />
        </Flex>
        <FormProvider {...methods}>
          <form
            className="updatePasswordForm"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <Box className="updatePasswordFormGroup">
              <PasswordInput
                name="password"
                rules={{
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                }}
                defaultValue=""
                type={"password"}
                placeHolder="Current Password"
                width={"90%"}
                withIcon
              />

              <PasswordInput
                name="newPassword"
                rules={{
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                  validate: {
                    capitalLetter: (value: string) =>
                      /[A-Z]/.test(value) ||
                      "Must contain atleast 1 Capital letter",
                    specialCharacter: (value: string) =>
                      /[!@#$%^&*]/.test(value) ||
                      "Must contain atleast 1 special characters",
                  },
                }}
                defaultValue=""
                type={"password"}
                placeHolder="Enter Password"
                width={"90%"}
                withIcon
              />

              <PasswordInput
                name="confirmPassword"
                rules={{
                  validate: (value: any) =>
                    value === watch("newPassword") || "Password does not match",
                }}
                defaultValue=""
                type={"password"}
                placeHolder="Confirm Password"
                width={"90%"}
                withIcon
              />
              <Flex pt={"16px"} w={"100%"}>
                <button
                  className="updatePasswordButton"
                  type="submit"
                  disabled={loading}
                >
                  {!loading && (
                    <Text mt={"auto"} mb={"auto"}>
                      CHANGE PASSWORD
                    </Text>
                  )}
                  {loading && <Spinner size={"xs"} color={"white"} />}
                </button>
              </Flex>
            </Box>
          </form>
        </FormProvider>
      </Flex>
    </Container>
  );
};

export default React.forwardRef(PasswordSettings);
