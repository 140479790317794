import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../../config/apiClient";
import {
  RESET_PASSWORD_API_URL,
  SUCCESS_CODE,
} from "../../../../config/constant";
import { showErrorToast } from "../../../../components/ui/atoms/Toast";
import { Box, Spinner, Text } from "@chakra-ui/react";
import Input from "../../../../components/ui/atoms/formElements/Input";

interface FormProps {
  setUserConfirmEmail: React.Dispatch<React.SetStateAction<string>>;
}

const Form: React.FC<FormProps> = ({ setUserConfirmEmail }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    setLoading(true);
    const { emailAddress } = data;
    const payload = {
      emailAddress,
    };

    try {
      const resp = await apiClient.get(
        encodeURI(`${RESET_PASSWORD_API_URL}/${emailAddress}`)
      );
      const { data: respData } = resp;
      if (respData.statusCode === SUCCESS_CODE) {
        setUserConfirmEmail(emailAddress);
      }
    } catch (e: any) {
      showErrorToast("Error", e?.message);
    }

    setLoading(false);
  };
  return (
    <>
      <FormProvider {...methods}>
        <form
          className="forgotPasswordForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Box className="forgotPasswordFormGroup">
            <Input
              name="emailAddress"
              rules={{
                required: {
                  value: true,
                  message: "Email is required",
                },
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid Email",
                },
              }}
              defaultValue=""
              width={"90%"}
              placeHolder="Enter Email"
              withIcon
            />
          </Box>
          <Box className="buttonContainer">
            <button
              type="button"
              className="backButton"
              onClick={() => navigate(-1)}
            >
              <span>&lt; </span> GO BACK
            </button>

            <button className="resetButton" type="submit">
              {!loading && (
                <Text mt={"auto"} mb={"auto"}>
                  YES, RESET
                </Text>
              )}
              {loading && <Spinner size={"xs"} color={"white"} />}
            </button>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default React.memo(Form);
