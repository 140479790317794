import { DashModalBodyReqState } from "../../model/DashModal.interface";
import { apiClient } from "../../config/apiClient";
import { RAISE_APP_REQ_API_URL } from "../../config/constant";

export const dashModelApi = async (
  details: DashModalBodyReqState,
  token: string
) => {
  const response = await apiClient.post(`${RAISE_APP_REQ_API_URL}`, details, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
