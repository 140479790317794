// ERROR CONSTANTS
export const SUCCESS_CODE = "0000";
export const ERROR_CODE = "9999";
export const VERIFY_LOGIN_USER_CODE = "2FA";

// API HOST
export const BASE_URL = process.env.REACT_APP_HOST_URL;

// END POINTS
export const LOGIN_API_URL = "/v1/user/login";
export const SIGNUP_API_URL = "/v1/user";
export const VERIFY_OTP_API_URL = "/v1/verifyotp";
export const RESET_PASSWORD_API_URL = "/v1/user/resetPassword";
export const CHANGE_PASSWORD_API_URL = "/v1/user/changePassword";
export const UPDATE_PROFILE_IMG_URL = "/v1/user/uploadProfileImage";
export const UPDATE_PROFILE_URL = "/v1/update";
export const GET_USER_API_URL = "/v1/user/getUserById";
export const RAISE_APP_REQ_API_URL = "/v1/saveUserFromCintap";
export const GENERATE_API_KEY = "/v1/key/generateApiKey";
export const GET_API_KEYS_BY_USERID = "/v1/key/getKeyByUserId";

export const queryKeys = {
    KEY_GENERATE_API : "keyGenerate",
    GET_KEYS_BY_USERID : "KeysByUserID"
}

export const connectionStatusCodes = {
    SUCCESS: "0000",
    FAILED: "9999",
};