import React from "react";
import {
  GENERATE_API_KEY,
  connectionStatusCodes,
  BASE_URL,
  GET_API_KEYS_BY_USERID,
} from "../../config/constant";
import http from "../../utils/http";
import { GenerateApiKeyBody } from "../../model/GenerateKey.interface";

export const keyGenerateApi = async (body: GenerateApiKeyBody) => {
  try {
    const response = await http.post(`${BASE_URL}${GENERATE_API_KEY}`, body, {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    });
    return response.status === 200
      ? response.data
      : { statusCode: connectionStatusCodes.FAILED };
  } catch (error) {
    console.log("Error in API", error);
  }
};

export const GetKeysByUserIdApi = async (userId: string, token: string) => {
  try {
    const response = await http.get(
      `${BASE_URL}${GET_API_KEYS_BY_USERID}/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.status === 200
      ? response.data
      : { statusCode: connectionStatusCodes.FAILED };
  } catch (error) {
    console.log("Error in API", error);
  }
};
