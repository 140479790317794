import { Avatar, Button, Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../state/reducers";
import "./profileInfo.scss";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../../../../theme/colors";
import React from "react";

interface ProfileInfoProps{
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileInfo:React.FC<ProfileInfoProps> = ({ setIsEdit }) => {
  
  const user = useSelector((state: RootState) => state.user.userData);
  const { imageLocation } = user ?? {};
  const profileImageURL = imageLocation
    ? `${process.env.REACT_APP_IMAGE_HOST_URL}/${imageLocation}`
    : "";

  const navigate = useNavigate();

  const secTextSx = {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "100%",
    letterSpacing: "0.178px",
  };

  const handleClick = () => {
    setIsEdit(true);
  };

  return (
    <>
      <Flex justifyContent={"center"} mb={"40px"}>
        <Avatar
          border={"1px solid #2c8526"}
          src={profileImageURL}
          borderRadius="20px"
          boxSize="140px"
        />
      </Flex>
      <Flex
        w={"100%"}
        h={"100%"}
        justifyContent="center"
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Flex
          w={"100%"}
          h={"100%"}
          justifyContent="center"
          alignItems={"center"}
          flexDirection={"column"}
          gap={"24px"}
        >
          <Flex
            w={"100%"}
            h={"41.5px"}
            borderBottom={"1px solid #DADADA"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Text sx={secTextSx}>First Name</Text>
            <Text sx={{ ...secTextSx, color: colors.lightBlack }}>
              {user?.firstName}
            </Text>
          </Flex>
          <Flex
            w={"100%"}
            h={"41.5px"}
            borderBottom={"1px solid #DADADA"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Text sx={secTextSx}>Last Name</Text>
            <Text sx={{ ...secTextSx, color: colors.lightBlack }}>
              {user?.lastName}
            </Text>
          </Flex>
          <Flex
            w={"100%"}
            h={"41.5px"}
            borderBottom={"1px solid #DADADA"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Text sx={secTextSx}>Phone Number</Text>
            <Text sx={{ ...secTextSx, color: colors.lightBlack }}>
              {user?.mobileNumber}
            </Text>
          </Flex>
          <Flex justifyContent={"space-between"} pt={"16px"} w={"100%"}>
            <Button
              minWidth={"48%"}
              borderRadius={"4px"}
              border={"1px solid #2c8526"}
              background={"white"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"120%"}
              letterSpacing={"0.6px"}
              padding={"8px 0"}
              width={"140px"}
              onClick={() => navigate(-1)}
            >
              CLOSE
            </Button>
            <Button 
              minWidth={"48%"}
              borderRadius={"4px"}
              background={colors.green}
              _hover={{backgroundColor:colors.green}}
              color={"white"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"120%"}
              letterSpacing={"0.6px"}
              padding={"8px 0"}
              width={"140px"}
              onClick={handleClick}>
              EDIT SETTINGS
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default React.memo(ProfileInfo);
