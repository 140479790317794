import React, { useEffect, useImperativeHandle, useState } from "react";
import "./profileEditForm.scss";
import { Box, Button, Flex } from "@chakra-ui/react";
import Input from "../../../../../ui/atoms/formElements/Input";
import { useForm, FormProvider } from "react-hook-form";
import { Text, Spinner } from "@chakra-ui/react";
import { apiClient } from "../../../../../../config/apiClient";
import ImageUpload from "../../../../../ui/atoms/ImageUpload";
import circleUI from "../../../../../../assets/images/check-circle.png";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../state/reducers";
import { AppDispatch } from "../../../../../../state/store";
import {
  fetchUser,
  updateUser,
} from "../../../../../../state/actions/userActions";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../../ui/atoms/Toast";
import { ERROR_CODE, SUCCESS_CODE } from "../../../../../../config/constant";
import { useNavigate } from "react-router-dom";

interface UpdateUserData {
  firstName: string;
  lastName: string;
  mobileNumber: string;
}

const ProfileEditForm = (
  { setIsEdit }: any,
  ref: React.Ref<unknown> | undefined
) => {
  const [imageFormData, setImageFormData] = useState<FormData | null>(null);
  const user = useSelector((state: RootState) => state.user.userData);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const { imageLocation } = user ?? {};
  const profileImageURL = imageLocation
    ? `${process.env.REACT_APP_IMAGE_HOST_URL}/${imageLocation}`
    : "";
  const navigate = useNavigate();

  const handleImageUpload = (formData: FormData) => {
    setImageFormData(formData);
  };
  const methods = useForm<UpdateUserData>();
  const { handleSubmit, reset } = methods;

  useImperativeHandle(
    ref,
    () => {
      return {
        resetForm: () => reset(),
      };
    },
    []
  );

  const handleBack = () => {
    setIsEdit(false);
  };

  const onSubmit = async (data: UpdateUserData) => {
    if (imageFormData) {
      const response = await apiClient.post(
        `/v1/user/uploadProfileImage/${user?.id}`,
        imageFormData,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      const { data: respData } = response;
      if (respData.statusCode === ERROR_CODE) {
        showErrorToast("Error", respData.statusMessage);
      }
    }

    if (user && user.id && data) {
      const response = await dispatch(
        updateUser({ user, updateUserData: data })
      );
      if (response.meta.requestStatus === "fulfilled") {
        showSuccessToast("Success", response?.payload?.statusMessage ?? "");
        setIsEdit(false);
      }
      await dispatch(fetchUser(user));
    }
  };

  return (
    <>
      <Flex
        justifyContent={"center"}
        borderRadius={"10px"}
        border={"3px solid #2c8526"}
        mb={"40px"}
      >
        <ImageUpload
          onSubmit={handleImageUpload}
          backgroundImage={profileImageURL}
        />
      </Flex>

      <FormProvider {...methods}>
        <form
          className="updateProfileForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Box className="updateProfileFormGroup">
            <Input
              name="firstName"
              rules={{
                required: {
                  value: true,
                  message: "First Name is required",
                },
              }}
              placeHolder="Enter First Name"
              defaultValue={user?.firstName}
              width={"90%"}
            />
            <Input
              name="lastName"
              rules={{
                required: {
                  value: true,
                  message: "Last Name is required",
                },
              }}
              placeHolder="Enter Last Name"
              defaultValue={user?.lastName}
              width={"90%"}
            />
            <Input
              name="mobileNumber"
              rules={{
                required: {
                  value: true,
                  message: "Mobile Number is required",
                },
                pattern: {
                  value: /^\d{1,10}$/,
                  message: "Please provide below 10 numbers",
                },
              }}
              defaultValue={user?.mobileNumber}
              placeHolder="Enter Phone Number"
              width={"90%"}
              type="number"
            />
            <Flex justifyContent={"space-between"} pt={"16px"} w={"100%"}>
              <Button
                minWidth={"48%"}
                borderRadius="4px"
                border={"1px solid #2c8526"}
                background={"white"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"120%"}
                letterSpacing={"0.6px"}
                padding={"8px 0"}
                width={"140px"}
                type="button"
                onClick={handleBack}
              >
                BACK
              </Button>
              <button className="updateProfileButton" type="submit">
                {!isLoading && (
                  <Text marginTop={"auto"} marginBottom={"auto"}>
                    SAVE CHANGE
                  </Text>
                )}
                {isLoading && <Spinner size={"xs"} color={"white"} />}
              </button>
            </Flex>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default React.forwardRef(ProfileEditForm);
