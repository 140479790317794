import { useEffect, useState } from "react";
import Input from "../../../../components/ui/atoms/formElements/Input";
import FormHeader from "../../../../components/ui/organism/FormHeader";
import FormFooter from "../../../../components/ui/organism/FormFooter";
import FormContainer from "../../../../components/ui/organism/FormContainer";
import { useForm, FormProvider } from "react-hook-form";
import "./loginForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/reducers";
import { AppDispatch } from "../../../../state/store";
import { fetchUser, loginUser } from "../../../../state/actions/userActions";
import { showErrorToast } from "../../../../components/ui/atoms/Toast";
import { resetUser } from "../../../../state/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { Box, Spinner } from "@chakra-ui/react";
import {
  ERROR_CODE,
  SUCCESS_CODE,
  VERIFY_LOGIN_USER_CODE,
} from "../../../../config/constant";
import PasswordInput from "../../../../components/ui/atoms/formElements/PasswordInput";

interface LoginCredentials {
  emailAddress: string;
  password: string;
}

const Form = ({ setLogInSuccess }: any) => {
  const navigate = useNavigate();
  const [verifyUser, setVerifyUser] = useState(false);
  const user = useSelector((state: RootState) => state.user.userData);
  const userError = useSelector((state: RootState) => state.user.error);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const dispatch = useDispatch<AppDispatch>();

  const methods = useForm<LoginCredentials>();
  const { handleSubmit } = methods;

  useEffect(() => {
    if (verifyUser) {
      setLogInSuccess((prev: any) => ({
        ...prev,
        email: user?.emailAddress,
        status: true,
        otpStatus: true,
      }));
      setVerifyUser(false);
    }
  }, [verifyUser]);

  useEffect(() => {
    if (user && user.id) {
      setLogInSuccess((prev: any) => ({
        ...prev,
        email: user?.emailAddress,
        status: true,
        appStatus: true,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (userError) {
      showErrorToast("Error", userError);
      dispatch(resetUser());
    }
  }, [userError]);

  const onSubmit = async (data: LoginCredentials) => {
    const response = await dispatch(loginUser(data));

    if (user && user.id && response?.payload?.statusCode === SUCCESS_CODE) {
      await dispatch(fetchUser(user));
    }

    if (user && !user?.id && response?.payload?.statusCode === ERROR_CODE) {
      showErrorToast("Error", response?.payload?.statusMessage ?? "");
    }

    if (
      user &&
      !user.id &&
      response?.payload?.statusCode === VERIFY_LOGIN_USER_CODE
    ) {
      setVerifyUser(true);
    }
  };

  // const loginUser = async (data: LoginCredentials) => {

  //     dispatch(loginUser(data))

  //     const resp = await apiClient.post(`/v1/user/login`, payload);
  //     const { data: respData } = resp;

  //     if (respData.statusCode === "0000") {
  //         localStorage.setItem(
  //             "userData",
  //             JSON.stringify(resp?.data?.result)
  //         );
  //         setLogInSuccess((prev: any) => ({
  //             ...prev,
  //             email: emailAddress,
  //             status: true,
  //             appStatus: true,
  //         }));
  //     } else if (respData.statusCode === "2FA") {
  //         setLogInSuccess((prev: any) => ({
  //             ...prev,
  //             email: emailAddress,
  //             status: true,
  //             otpStatus: true,
  //         }));
  //     }
  // };
  return (
    <>
      <FormProvider {...methods}>
        <form
          className="loginForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Box className="loginFormGroup">
            <Input
              name="emailAddress"
              rules={{
                required: {
                  value: true,
                  message: "Email is required",
                },
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid Email",
                },
              }}
              defaultValue=""
              width={"90%"}
              placeHolder="Enter Email"
              withIcon
            />
          </Box>
          <Box className="loginFormGroup">
            <PasswordInput
              name="password"
              rules={{
                required: {
                  value: true,
                  message: "Password is required",
                },
              }}
              withIcon
              defaultValue=""
              type={"password"}
              placeHolder="Password"
              width={"90%"}
            />
          </Box>
          <Box
            className="forgetPasswordText"
            onClick={() => navigate("/forgetpassword")}
          >
            FORGOT PASSWORD?
          </Box>
          <button
            className="loginButton"
            style={{ margin: "20px 0 28px 0" }}
            disabled={isLoading}
          >
            {!isLoading && "SIGN IN"}
            {isLoading && <Spinner color="white.500" size="xs" />}
          </button>
        </form>
      </FormProvider>
    </>
  );
};

const LoginForm = ({ setLogInSuccess }: any) => {
  const navigate = useNavigate();
  return (
    <FormContainer>
      <FormHeader
        headerTypeText="Sign in to"
        subHeaderText="Welcome back! please enter your details"
      />
      <Form setLogInSuccess={setLogInSuccess} />
      <FormFooter
        footerTitle="Don't have an account?"
        linkText="SIGN UP NOW"
        handleClick={() => navigate("/signup")}
      />
    </FormContainer>
  );
};

export default LoginForm;
