import React from "react";
import { useMutation, useQuery } from "react-query";
import {
  keyGenerateApi,
  GetKeysByUserIdApi,
} from "../api/generateApi/generateApis";
import { queryKeys } from "../config/constant";
import { GenerateApiKeyBody } from "../model/GenerateKey.interface";

export const useKeyGenerateApi = () => {
  return useMutation(
    [queryKeys.KEY_GENERATE_API],
    (body: GenerateApiKeyBody) => keyGenerateApi(body),
    {
      onError: (error: Error) => {
        console.log("error", { message: error.message });
      },
    }
  );
};

export const useGetKeysByUserIdApi = (userId: string, token: string) => {
  return useQuery(
    [queryKeys.GET_KEYS_BY_USERID],
    () => GetKeysByUserIdApi(userId, token),
    {
      onError: (error: Error) => {
        console.log("error", { message: error.message });
      },
    }
  );
};
