import axios from "axios";

const http = axios.create({
  responseType: "json",
  timeout: 120000, // timeout set to 2 min
});

// http.interceptors.request.use(
//   async (config: any) => {
//     const authToken = await authentication.getAuthToken();
//     config.headers = {
//       Authorization: `Bearer ${authToken}`,
//     };
//     return config;
//   },
//   (error) => {
//     console.error("HTTP interceptors request error: ", error);
//     //TODO: should redirect to some default page (yet to decide).
//   }
// );

http.interceptors.response.use(
  (response) => response,
  (error) => error
);

export default http;
