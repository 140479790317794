import React, { useRef, ChangeEvent, useState } from "react";
import { Flex } from "@chakra-ui/react";
import EditableSquareAvatar from "../EditableSquareAvatar";

interface ImageUploadProps {
  onSubmit: (formData: FormData) => void;
  backgroundImage: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onSubmit,
  backgroundImage,
}) => {
  
  // save the preview file in this state and use that selectedFile state in <EditableSquareAvatar src={selectedFile ? URL.createObjectURL(selectedFile) : ""} size="140px" />
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const formData = new FormData();
      formData.append("profileImage", file);
      onSubmit(formData);
    }
  };

  return (
    <>
      <Flex onClick={handleImageUpload} cursor={"pointer"}>
        <input
          type="file"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <EditableSquareAvatar
          src={selectedFile ? URL.createObjectURL(selectedFile) : ""}
          size="140px"
        />
      </Flex>
    </>
  );
};

export default React.memo(ImageUpload);
