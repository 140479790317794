import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Box } from "@chakra-ui/react";
import { colors } from "../../../../theme/colors";

function Footer() {
  return (
    <Box>
      <footer className="footer py-1" style={{ backgroundColor: colors.footerGreen }}>
        <Box className="ps-3">
          <span className="text-white" style={{fontWeight:"bold"}}>© 2024 Adroitco</span>
        </Box>
      </footer>
    </Box>
  );
}

export default React.memo(Footer);
