import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  Box,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { useKeyGenerateApi } from "../../../../../queries/useGenerateQueries";
import { colors } from "../../../../../theme/colors";

interface GenerateModalProps {
  isOpen: boolean;
  handleClose: () => void;
  appId: number;
  handleConfirmGenerate: (id: number) => void;
}

const GenerateKeyButton: React.FC<GenerateModalProps> = ({
  isOpen,
  handleClose,
  appId,
  handleConfirmGenerate,
}) => {
  const {
    mutate: generateMutate,
    data: generateData,
    isLoading,
  } = useKeyGenerateApi();

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold" color="black">
          Generate New Key
        </ModalHeader>
        <ModalBody>
          <Text>
            "It will regenerate key and existing key has to be replaced where it
            is used"."Do you want to regenerate the key"?
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4} align="stretch">
            <Button
              onClick={() => handleConfirmGenerate(appId)}
              textColor="white"
              _hover={{ backgroundColor: colors.green }}
              backgroundColor={colors.green}
              isDisabled={isLoading}
            >
              {isLoading ? <Spinner /> : "Generate"}
            </Button>
            <Button
              onClick={handleClose}
              _hover={{ backgroundColor: "red" }}
              backgroundColor={"red"}
              textColor="white"
            >
              Cancel
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(GenerateKeyButton);
