import { Avatar, Box, Flex, Img, Text } from "@chakra-ui/react";
import editIcon from "../../../../assets/icons/editIcon.svg";
import React from "react";

interface EditableSquareAvatarProps{
  src : string;
  size : string;
}

const EditableSquareAvatar:React.FC<EditableSquareAvatarProps> = ({ src, size }) => {

  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      boxSize={size}
    >
      <Avatar src={src} borderRadius="md" boxSize="100%" />
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        borderRadius="md"
        opacity="1"
        transition="opacity 0.3s ease-in-out"
        _hover={{ opacity: 1 }}
      >
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          h="100%"
          gap={"4px"}
        >
          <Img src={editIcon} w="45px" h={"45px"} />
        </Flex>
      </Box>
    </Flex>
  );
};

export default React.memo(EditableSquareAvatar);
