import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormLabel,
  IconButton,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { colors } from "../../../../../theme/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../state/reducers";
import {
  useGetKeysByUserIdApi,
  useKeyGenerateApi,
} from "../../../../../queries/useGenerateQueries";
import { toast } from "react-toastify";
import GenerateKeyButton from "./GenerateKeyButton";
import { GenerateApiKeyValueBodyState } from "../../../../../model/GenerateKey.interface";

const ApiKeyGenerate = () => {
  const [generatedData, setGeneratedData] = useState<
    GenerateApiKeyValueBodyState[]
  >([]);
  console.log(generatedData);
  const [copiedValue, setCopiedValue] = useState<string>("");
  console.log(copiedValue);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user.userData);
  // console.log(user);
  const inputRef = useRef(null);
  const [appId, setAppId] = useState(0);

  const handleCopy = (value: string) => {
    if (inputRef.current) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          toast.success("Copied API Key.", {
            position: "top-center",
            theme: "colored",
            autoClose: 1000,
          });
        })
        .catch((err) => {
          toast.error("Copy API Key Failed.", {
            position: "top-center",
            theme: "colored",
            autoClose: 1000,
          });
        });
    }
  };

  const { data: getKeysByUserIdData, refetch: getKeysByUserIdReftech } =
    useGetKeysByUserIdApi(user?.id || "", user?.token || "");

  useEffect(() => {
    if (getKeysByUserIdData?.statusCode == "0000") {
      setGeneratedData(getKeysByUserIdData?.result);
    }
  }, [getKeysByUserIdData]);

  const {
    mutate: generateMutate,
    data: generateData,
    isSuccess: generateSuccess,
  } = useKeyGenerateApi();

  const handleGeneratekey = (id: number) => {
    const body = {
      userId: user?.id || "",
      appId: id,
      token: user?.token || "",
    };
    generateMutate(body);
  };

  useEffect(() => {
    if (generateData) {
      getKeysByUserIdReftech();
    }
  }, [generateData]);

  const handleConfirmGenerate = (id: number) => {
    const body = {
      userId: user?.id || "",
      appId: id,
      token: user?.token || "",
    };
    generateMutate(body);
  };

  useEffect(() => {
    if (generateSuccess) {
      toast.success("Key Generated Success.", {
        position: "top-center",
        theme: "colored",
        autoClose: 1000,
      });
    }
    handleClose();
  }, [generateSuccess]);

  const handleGenerateModal = (id: number) => {
    setIsModalOpen(true);
    setAppId(id);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Container
      display={"flex"}
      justifyContent={"center"}
      height={"70vh"}
      pt={"50px"}
    >
      <Box>
        <TableContainer w={"720px"}>
          <Table variant="simple" border="1px" borderColor="gray.300">
            <Thead>
              <Tr>
                <Th
                  border="1px"
                  borderColor="gray.300"
                  textAlign={"center"}
                  textTransform="none"
                  backgroundColor={colors.paleGreen}
                  fontSize={"14px"}
                >
                  App Name
                </Th>
                <Th
                  border="1px"
                  borderColor="gray.300"
                  textAlign={"center"}
                  textTransform="none"
                  backgroundColor={colors.paleGreen}
                  fontSize={"14px"}
                >
                  Api Key
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {generatedData.length > 0 ? (
                generatedData.map((item, index) => (
                  <Tr key={index}>
                    <Td border="1px" borderColor="gray.300">
                      <Text
                        textAlign={"center"}
                        mt={"auto"}
                        mb={"auto"}
                        fontSize={"12px"}
                      >
                        {item?.appName}
                      </Text>
                    </Td>

                    <Td border="1px" borderColor="gray.300">
                      <Input
                        ref={inputRef}
                        value={item?.apiKey}
                        onChange={(e) => setCopiedValue(e.target.value)}
                        size="sm"
                        width="calc(100% - 200px)"
                        mr={4}
                        isReadOnly
                        pb={"3.5px"}
                      />
                      <IconButton
                        icon={<CopyIcon color="white" />}
                        aria-label="Copy"
                        mr={4}
                        h={8}
                        backgroundColor={colors.skyBlue}
                        _hover={{ backgroundColor: colors.skyBlue }}
                        onClick={() => handleCopy(item?.apiKey)}
                      />
                      {item?.apiKey != "" ? (
                        <Button
                          onClick={() => handleGenerateModal(item?.appId)}
                          backgroundColor={colors.green}
                          _hover={{ backgroundColor: colors.green }}
                          size="sm"
                          textColor={"white"}
                        >
                          Generate New Key
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleGeneratekey(item?.appId)}
                          backgroundColor={colors.green}
                          _hover={{ backgroundColor: colors.green }}
                          size="sm"
                          textColor={"white"}
                        >
                          Generate Key
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td
                    colSpan={2}
                    textAlign="center"
                    border="1px"
                    borderColor="gray.300"
                  >
                    No Records Found
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <GenerateKeyButton
        handleConfirmGenerate={handleConfirmGenerate}
        handleClose={handleClose}
        isOpen={isModalOpen}
        appId={appId}
      />
    </Container>
  );
};

export default React.forwardRef(ApiKeyGenerate);
