import { useNavigate } from "react-router-dom";
import FormContainer from "../../../../components/ui/organism/FormContainer";
import FormFooter from "../../../../components/ui/organism/FormFooter";
import FormHeader from "../../../../components/ui/organism/FormHeader";
import { Button, Flex, Text } from "@chakra-ui/react";
import { colors } from "../../../../theme/colors";

const ResetPasswordInfo = ({ userConfirmEmail }: any) => {
  const navigate = useNavigate();
  const resetTextSx = {
    color: colors.sonicSilver,
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "120%",
    letterSpacing: "0.6px",
  };

  const spamTextSx = {
    color: colors.yellow,
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "120%",
    letterSpacing: "0.6px",
    marginLeft: "4px",
  };
  const BackButtonSx = {
    borderRadius: "4px",
    border: "1px solid #2c8526",
    background: "white",
    color: colors.darkGrey,
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "120%",
    letterSpacing: "0.6px",
    padding: "8px 20px",
    minWidth: "300px",
  };
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      h="100vh"
      flexBasis={"40%"}
      textAlign={"center"}
    >
      <FormContainer>
        <FormHeader
          subHeaderText={`if an account exists for <b>&lt;${
            userConfirmEmail ?? "your EMAIL ID"
          }&gt;</b>, you will get an email with
            instructions on resetting your password.`}
        />
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          p={"28px"}
          gap={"28px"}
        >
          <Flex>
            <Text sx={resetTextSx}>
              {" if it doesn't arrive, be sure to check your "}
            </Text>
            <Text sx={spamTextSx}>spam folder</Text>
          </Flex>
          <Button sx={BackButtonSx} onClick={() => navigate("/login")}>
            <span style={{ marginRight: "10px" }}>&lt;</span>GO BACK TO LOGIN
          </Button>
        </Flex>
        <FormFooter
          footerTitle="Don't have an account?"
          linkText="SIGN UP NOW"
          handleClick={() => navigate("/signup")}
        />
      </FormContainer>
    </Flex>
  );
};

export default ResetPasswordInfo;
