import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Box,
} from "@chakra-ui/react";
import { useRef } from "react";
import PasswordSettings from "./ProfileTabs/PasswordSettings";
import ProfileSettings from "./ProfileTabs/ProfileSettings";
import { colors } from "../../../theme/colors";
import ApiKeyGenerate from "./ProfileTabs/ApikeyGenerate/ApiKeyGenerate";

const ProfileComponent = () => {
  const profileSettingsRef = useRef<any>();
  const passwordSettingsRef = useRef<any>();
  const apiKeyGenerateRef = useRef<any>();
  return (
    <Box w={"100%"}>
      <Tabs w={"100%"}>
        <TabList>
          <Tab
            onClick={() => passwordSettingsRef?.current?.resetForm()}
            _selected={{ color: colors.green, fontWeight: 700 }}
          >
            Profile Settings
          </Tab>
          <Tab
            onClick={() => profileSettingsRef?.current?.resetForm()}
            _selected={{ color: colors.green, fontWeight: 700 }}
          >
            Password Settings
          </Tab>
          <Tab
            onClick={() => apiKeyGenerateRef?.current?.resetForm()}
            _selected={{ color: colors.green, fontWeight: 700 }}
          >
            API Key Generate
          </Tab>
        </TabList>

        <TabPanels height={100}>
          <TabPanel height={"100%"}>
            <ProfileSettings ref={profileSettingsRef} />
          </TabPanel>
          <TabPanel height={"100%"}>
            <PasswordSettings ref={passwordSettingsRef} />
          </TabPanel>
          <TabPanel height={"100%"}>
            <ApiKeyGenerate ref={apiKeyGenerateRef} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ProfileComponent;
