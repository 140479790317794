import React, { useState } from "react";
import { render } from "react-dom";
import {
  Flex,
  Grid,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  useDisclosure,
  Textarea,
} from "@chakra-ui/react";
import { colors } from "../../../theme/colors";

interface DashModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (text: string) => void;
}

const DashModal: React.FC<DashModalProps> = ({
  isOpen,
  handleClose,
  handleSubmit,
}) => {
  const [textMessage, setTextMessage] = useState<string>("");

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Send us the request.</FormLabel>
            <Textarea
              value={textMessage}
              onChange={(e) => {
                setTextMessage(e.target.value);
              }}
              rows={5}
            />
            <FormHelperText>Please describe your requirement.</FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            _hover={{ backgroundColor: "lightgrey" }}
            backgroundColor={colors.paleGreen}
            type="submit"
            form="new-form"
            onClick={() => {
              handleSubmit(textMessage);
            }}
          >
            Submit
          </Button>
          <Box ml={3}>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(DashModal);
