import { apiClient } from "../../config/apiClient";
import {
  GET_USER_API_URL,
  LOGIN_API_URL,
  SUCCESS_CODE,
  UPDATE_PROFILE_URL,
  VERIFY_OTP_API_URL,
} from "../../config/constant";

interface LoginCredentials {
  emailAddress: string;
  password: string;
}

interface VerifyOtpData {
  emailAddress: string;
  otp: string;
}

interface UpdateUserData {
  firstName: string;
  lastName: string;
  mobileNumber: string;
}

interface UserData {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  imageLocation?: string;
  mobileNumber: string;
  token: string;
}

interface UserResponse {
  data: {
    result: UserData;
    statusCode: string;
    statusMessage: string;
  };
}

interface UpdatePayload {
  user: UserData;
  updateUserData: UpdateUserData;
}

export const loginUserAPI = async (credentials: LoginCredentials) => {
  const response = await apiClient.post(LOGIN_API_URL, credentials);

  return response;
};

export const logoutUserAPI = async () => {
  return Promise.resolve("logout success");
};

export const verifyUserAPI = async (data: VerifyOtpData) => {
  const response = await apiClient.post(VERIFY_OTP_API_URL, data);
  return response.data;
};

export const updateUserAPI = async (data: UpdatePayload) => {
  const { user, updateUserData } = data;
  const { firstName, lastName, mobileNumber } = updateUserData;

  const response = await apiClient.put(
    UPDATE_PROFILE_URL,
    {
      id: user.id,
      firstName,
      lastName,
      mobileNumber,
      emailAddress: user?.emailAddress,
    },
    {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    }
  );

  return response.data;
};

export const fetchUserAPI = async (user: UserData) => {
  const response = await apiClient.get(`${GET_USER_API_URL}/${user?.id}`, {
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  });
  return response.data;
};
