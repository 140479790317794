// CustomIcon.tsx
import React from "react";
import { ChakraProps, chakra } from "@chakra-ui/react";

type CustomIconProps = ChakraProps;

const LogoutIcon: React.FC<CustomIconProps> = (props) => {
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11.3046 16.6183C11.1226 16.6183 10.9481 16.6893 10.8195 16.8157C10.6908 16.9422 10.6185 17.1137 10.6185 17.2925C10.6185 17.4713 10.6908 17.6428 10.8195 17.7692C10.9481 17.8956 11.1226 17.9667 11.3046 17.9667H16.7934C17.2179 17.9667 17.6251 17.8009 17.9254 17.5059C18.2256 17.2109 18.3942 16.8108 18.3942 16.3935V5.60644C18.3942 5.18923 18.2256 4.7891 17.9254 4.49408C17.6251 4.19906 17.2179 4.03333 16.7934 4.03333H11.3046C11.1226 4.03333 10.9481 4.10436 10.8195 4.23079C10.6908 4.35723 10.6185 4.52871 10.6185 4.70752C10.6185 4.88633 10.6908 5.05781 10.8195 5.18425C10.9481 5.31068 11.1226 5.38171 11.3046 5.38171H16.7934C16.854 5.38171 16.9122 5.40539 16.9551 5.44753C16.998 5.48968 17.0221 5.54684 17.0221 5.60644V16.3935C17.0221 16.4531 16.998 16.5103 16.9551 16.5525C16.9122 16.5946 16.854 16.6183 16.7934 16.6183H11.3046Z"
        fill="#B90D2C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5943 13.1259C14.2255 13.1259 14.7378 12.6226 14.7378 12.0023V9.9887C14.7378 9.36844 14.2255 8.86505 13.5943 8.86505H9.37439C9.36857 8.7991 9.36247 8.73318 9.35609 8.66728L9.30669 8.16748C9.28923 7.99096 9.2287 7.82117 9.13024 7.67253C9.03178 7.52389 8.89829 7.40077 8.7411 7.31361C8.58391 7.22645 8.40765 7.17783 8.22726 7.17187C8.04688 7.1659 7.86769 7.20277 7.70489 7.27934C6.20969 7.98312 4.85605 8.94633 3.70907 10.1226L3.61851 10.2161C3.41399 10.4258 3.2998 10.705 3.2998 10.9955C3.2998 11.2859 3.41399 11.5652 3.61851 11.7749L3.70999 11.8675C4.85663 13.044 6.20996 14.0075 7.70489 14.7117C7.86769 14.7882 8.04688 14.8251 8.22726 14.8191C8.40765 14.8132 8.58391 14.7645 8.7411 14.6774C8.89829 14.5902 9.03178 14.4671 9.13024 14.3185C9.2287 14.1698 9.28923 14 9.30669 13.8235L9.35609 13.3237L9.37439 13.1259H13.5943ZM8.73861 11.7776C8.56402 11.7777 8.39605 11.8432 8.26892 11.9608C8.14179 12.0784 8.06505 12.2392 8.05434 12.4104C8.03696 12.6711 8.01592 12.9327 7.99031 13.1934L7.97567 13.339C6.78147 12.7341 5.69519 11.9426 4.75926 10.9955C5.69515 10.0483 6.78144 9.25682 7.97567 8.652L7.99031 8.79763C8.01592 9.05831 8.03696 9.319 8.05434 9.58059C8.06505 9.75182 8.14179 9.91261 8.26892 10.0302C8.39605 10.1478 8.56402 10.2133 8.73861 10.2134H13.3656V11.7776H8.73861Z"
        fill="#B90D2C"
      />
    </chakra.svg>
  );
};

export default React.memo(LogoutIcon);
