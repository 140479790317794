import FormHeader from "../../../components/ui/organism/FormHeader";
import FormFooter from "../../../components/ui/organism/FormFooter";
import FormContainer from "../../../components/ui/organism/FormContainer";
import { Box } from "@chakra-ui/react";
import "./forgetPasswordForm.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ResetPasswordInfo from "./ResetPasswordInfo";
import React from "react";
import { ROUTER_ENUM } from "../../../model/Router.enum";
import Form from "../ForgetPassword/ForgotPasswordForm/index";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [userConfirmEmail, setUserConfirmEmail] = useState("");
  return (
    <Box className="forgetPasswordContainer">
      {userConfirmEmail ? (
        <ResetPasswordInfo userConfirmEmail={userConfirmEmail} />
      ) : (
        <FormContainer>
          <FormHeader
            subHeaderText={`Are you sure you want to reset your password?`}
          />
          <Form setUserConfirmEmail={setUserConfirmEmail} />
          <FormFooter
            footerTitle="Don't have an account?"
            linkText="SIGN UP NOW"
            handleClick={() => navigate(ROUTER_ENUM.SIGN_UP)}
          />
        </FormContainer>
      )}
    </Box>
  );
};

export default React.memo(ForgetPassword);
