
export const colors = {
    green:"#2c8526",
    yellow:"#D2A507",
    paleGreen:"#d2f2d4",
    footerGreen:"#009c1a",
    lightGrey:"#EDEDED",
    darkGrey:"#353535",
    lightBlack:"#363636",
    lightestGrey:"#949494",
    darkBlue:"#0faadb",
    sonicSilver:"#757575",
    skyBlue:"#00BFFF",
    lightBlue:"#87CEEB"
}