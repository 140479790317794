import axios from "axios";
import React, { useEffect, useState } from "react";
import airDrive from "../../../assets/images/dashboard/air-drive.png";
import airVault from "../../../assets/images/dashboard/air-vault.png";
import airScrapex from "../../../assets/images/dashboard/air-scrapex.png";
import airProjects from "../../../assets/images/dashboard/air-projects.png";
import airAnalytics from "../../../assets/images/dashboard/air-analytics.png";
import airAtlas from "../../../assets/images/dashboard/air-atlas.png";
import airErp from "../../../assets/images/dashboard/air-erp.svg";
import airHRM from "../../../assets/images/dashboard/air-hrm.svg";
import airProductCatalogue from "../../../assets/images/dashboard/air-product-catalogue.svg";
import DashModal from "../../../components/modules/dashModal/dashModal";
import { RootState } from "../../../state/reducers";
import { useSelector } from "react-redux";
import {
  Flex,
  Grid,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  useDisclosure,
  GridItem,
  Textarea,
  Text,
  Image,
} from "@chakra-ui/react";
import "./appCard.scss";
import { colors } from "../../../theme/colors";

interface AppCardProps {
  appIcon: string;
  _new: boolean;
  headerTitle: string;
  headerSubTitle: string;
  handleSubmit: () => void;
}

const AppCard: React.FC<AppCardProps> = ({
  _new,
  appIcon,
  handleSubmit,
  headerSubTitle,
  headerTitle,
}) => {
  const user = useSelector((state: RootState) => state.user.userData);
  const { token } = user ?? {};
  const [textMessage, setTextMessage] = useState<string>("");

  console.log(textMessage);

  const handleComingSoon = () => {
    return null;
  };

  return (
    <GridItem
      onClick={
        headerTitle == "AS2" ||
        headerTitle == "VAULT" ||
        headerTitle == "DRIVE" ||
        _new == false
          ? handleSubmit
          : handleComingSoon
      }
      _hover={_new ? { bg: "#FFF8DD" } : { bg: "#d2f2d4" }}
      p={2}
      height={"120px"}
      alignContent={"center"}
      role="group"
      position={"relative"}
      cursor={"pointer"}
      borderRadius={"8px"}
      border={"1px"}
      borderColor={"#DADADA"}
      backgroundColor={"white"}
      boxShadow={"md"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={3}
        borderRadius={"8px"}
        borderColor={"lightgrey"}
      >
        <Box>
          <Image src={appIcon} width="40px" height="40px" />
        </Box>

        <Box flex={1} className="textBox">
          <Text
            fontSize={"16px"}
            fontWeight={700}
            display={"flex"}
            marginBottom={0}
            lineHeight={"16px"}
            letterSpacing={0.18}
          >
            {"AIR"}
            <p
              style={{
                paddingLeft: "4px",
                marginBottom: "8px",
                fontWeight: "lighter",
              }}
            >
              {headerTitle}
            </p>
          </Text>

          <Text
            letterSpacing={0.6}
            lineHeight={"16px"}
            fontWeight={400}
            fontSize={"smaller"}
            marginBottom={0}
            color={"gray"}
          >
            {headerSubTitle}
          </Text>
        </Box>

        {_new ? (
          <Button
            _groupHover={{
              display: "block",
              position: "absolute",
              top: "0px",
              right: "0px",
              backgroundColor: _new ? colors.yellow : colors.green,
            }}
            display={"none"}
            w={"115px"}
            h={"18px"}
            borderTopRightRadius={"8px"}
            borderBottomLeftRadius={"8px"}
            borderTopLeftRadius={"0px"}
            borderBottomRightRadius={"0px"}
            backgroundColor={_new ? colors.yellow : colors.green}
            onClick={
              headerTitle != "AS2" &&
              headerTitle != "VAULT" &&
              headerTitle != "DRIVE"
                ? handleComingSoon
                : handleSubmit
            }
          >
            <Text
              color={"white"}
              fontFamily={"roboto"}
              fontSize={"14px"}
              fontWeight={700}
              marginTop={"1px"}
            >
              {headerTitle != "AS2" &&
              headerTitle != "VAULT" &&
              headerTitle != "DRIVE"
                ? "Coming Soon"
                : "Try Now"}
            </Text>
          </Button>
        ) : (
          <Button
            _groupHover={{
              display: "block",
              position: "absolute",
              top: "0px",
              right: "0px",
              backgroundColor: _new ? colors.yellow : colors.green,
            }}
            display={"none"}
            w={"80px"}
            h={"18px"}
            borderTopRightRadius={"8px"}
            borderBottomLeftRadius={"8px"}
            borderTopLeftRadius={"0px"}
            borderBottomRightRadius={"0px"}
            backgroundColor={_new ? colors.yellow : colors.green}
            onClick={handleSubmit}
          >
            <Text
              color={"white"}
              fontFamily={"roboto"}
              fontSize={"14px"}
              fontWeight={700}
              marginTop={"1px"}
            >
              Launch
            </Text>
          </Button>
        )}
      </Box>
    </GridItem>
  );
};

export default React.memo(AppCard);
