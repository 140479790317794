import FormHeader from "../../../../components/ui/organism/FormHeader";
import FormFooter from "../../../../components/ui/organism/FormFooter";
import FormContainer from "../../../../components/ui/organism/FormContainer";
import "./otpForm.css";
import OTPForm from "../../../../components/modules/OTPForm";
import { apiClient } from "../../../../config/apiClient";
import { fetchUser, verifyUser } from "../../../../state/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/reducers";
import { AppDispatch } from "../../../../state/store";
import React, { useEffect } from "react";
import { showErrorToast } from "../../../../components/ui/atoms/Toast";
import { resetUser } from "../../../../state/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { ERROR_CODE } from "../../../../config/constant";

interface VerifyOtpData {
  emailAddress: string;
  otp: string;
}

interface otpVerifySuccessProps{
  email: string;
  status: boolean;
}
interface SingUpOTPFormProps{
  emailAddress: string;
  setOtpVerifySuccess: React.Dispatch<React.SetStateAction<otpVerifySuccessProps>>;
}

const SingUpOTPForm:React.FC<SingUpOTPFormProps> = ({ emailAddress, setOtpVerifySuccess }) => {

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const userError = useSelector((state: RootState) => state.user.error);
  const user = useSelector((state: RootState) => state.user.userData);

  const handleOtpSubmit = async (otp = "") => {
    const payload = {
      emailAddress,
      otp,
    } as VerifyOtpData;

    const response = await dispatch(verifyUser(payload));

    if (response?.payload?.statusCode === ERROR_CODE) {
      showErrorToast("Error", response?.payload?.statusMessage ?? "");
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchUser(user));
      setOtpVerifySuccess({ email: emailAddress, status: true });
    }
  }, [user]);
  return (
    <FormContainer>
      <FormHeader
        headerTypeText="Sign up to"
        subHeaderText={`We have sent you an  OTP to <span class="signUpEmailText">&lt;${emailAddress}&gt;</span>`}
      />
      <OTPForm inputLength={6} onSubmit={handleOtpSubmit} />
      <FormFooter
        footerTitle="Already have an account?"
        linkText="SIGN IN NOW"
        handleClick={() => navigate("/login")}
      />
    </FormContainer>
  );
};

export default React.memo(SingUpOTPForm);
