import { Box, Button, GridItem, Text, Image } from "@chakra-ui/react";
import "./menuCard.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/reducers";
import { useState } from "react";
import { colors } from "../../../theme/colors";

interface MenuCardProps {
  index: number;
  _new: boolean;
  len: number;
  appIcon: string;
  headerTitle: string;
  handleAppButtonClick: () => void;
  handleSubmit: () => void;
}

const MenuCard: React.FC<MenuCardProps> = ({
  appIcon,
  _new,
  headerTitle,
  handleSubmit,
  index,
  len,
  handleAppButtonClick,
}) => {
  const user = useSelector((state: RootState) => state.user.userData);
  const { token } = user ?? {};
  const [textMessage, setTextMessage] = useState<string>("");

  const handleComingSoon = () => {
    return null;
  };

  return (
    <GridItem
      colSpan={index === len - 1 ? 2 : 1}
      _hover={_new ? { bg: "#FFF8DD" } : { bg: "#d2f2d4" }}
      w={"auto"}
      h={"85px"}
      alignContent={"center"}
      cursor={"pointer"}
      borderRadius={"8px"}
      border={"1px"}
      borderColor={"#DADADA"}
      backgroundColor={"white"}
      gap={"6px"}
      role="group"
      position={"relative"}
      onClick={
        headerTitle == "AS2" ||
        headerTitle == "VAULT" ||
        headerTitle == "DRIVE" ||
        _new == false
          ? handleSubmit
          : handleComingSoon
      }
    >
      <Box
        width="31px"
        height="31px"
        marginLeft={"auto"}
        marginRight={"auto"}
        marginBottom={"2px"}
      >
        <Image src={appIcon} />
      </Box>

      <Box
        width={"100%"}
        height={"16px"}
        marginLeft={"auto"}
        marginRight={"auto"}
      >
        <Text
          fontSize={"16px"}
          fontWeight={700}
          marginBottom={0}
          lineHeight={"16px"}
          letterSpacing={0.18}
          textAlign={"center"}
        >
          {"AIR"}
          <span
            style={{
              paddingLeft: "4px",
              fontWeight: 500,
              font: "Roboto",
              fontSize: "14px",
            }}
          >
            {headerTitle}
          </span>
        </Text>
      </Box>

      {_new ? (
        <Button
          _groupHover={{
            display: "block",
            position: "absolute",
            top: "0px",
            right: "0px",
            backgroundColor: _new ? colors.yellow : colors.green,
          }}
          display={"none"}
          w={"115px"}
          h={"18px"}
          borderTopRightRadius={"8px"}
          borderBottomLeftRadius={"8px"}
          borderTopLeftRadius={"0px"}
          borderBottomRightRadius={"0px"}
          backgroundColor={_new ? colors.yellow : colors.green}
          onClick={
            headerTitle != "AS2" &&
            headerTitle != "VAULT" &&
            headerTitle != "DRIVE"
              ? handleComingSoon
              : handleSubmit
          }
        >
          <Text
            color={"white"}
            fontFamily={"roboto"}
            fontSize={"14px"}
            fontWeight={700}
            marginTop={"1px"}
          >
            {headerTitle != "AS2" &&
            headerTitle != "VAULT" &&
            headerTitle != "DRIVE"
              ? "Coming Soon"
              : "Try Now"}
          </Text>
        </Button>
      ) : (
        <Button
          _groupHover={{
            display: "block",
            position: "absolute",
            top: "0px",
            right: "0px",
            backgroundColor: _new ? colors.yellow : colors.green,
          }}
          display={"none"}
          w={"80px"}
          h={"18px"}
          borderTopRightRadius={"8px"}
          borderBottomLeftRadius={"8px"}
          borderTopLeftRadius={"0px"}
          borderBottomRightRadius={"0px"}
          backgroundColor={_new ? colors.yellow : colors.green}
          onClick={handleSubmit}
        >
          <Text
            color={"white"}
            fontFamily={"roboto"}
            fontSize={"14px"}
            fontWeight={700}
            marginTop={"1px"}
          >
            Launch
          </Text>
        </Button>
      )}
    </GridItem>
  );
};

export default MenuCard;
