import { forwardRef, useState } from "react";
import "./passwordInput.scss";
import eyeOpenIcon from "../../../../../assets/icons/eye-open.svg";
import eyeCloseIcon from "../../../../../assets/icons/eye-close.svg";
import { Controller, useFormContext } from "react-hook-form";
import { Box } from "@chakra-ui/react";

const PasswordInput = forwardRef((props: any, ref) => {
  const [passwordIcon, setPasswordIcon] = useState("close");
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    type = "text",
    placeHolder = "",
    width = "100%",
    withIcon,
    name,
    defaultValue,
    rules,
    ...rest
  } = props;
  // console.log("props =====> ", props);
  const handleClick = () => {
    if (passwordIcon === "close") {
      setPasswordIcon("open");
    }
    if (passwordIcon === "open") {
      setPasswordIcon("close");
    }
  };
  let inputBorderColor = "#2c8526";
  console.log("errors ====> ", errors);
  if (
    (props.name === "password" || props.name === "newPassword") &&
    (errors?.password || errors.newPassword)
  ) {
    if (
      errors.password?.type === "minLength" ||
      errors.newPassword?.type === "minLength"
    )
      inputBorderColor = "#C71313";
    else if (
      errors.password?.type === "capitalLetter" ||
      errors.newPassword?.type === "capitalLetter"
    ) {
      inputBorderColor = "#EB9A3B";
    } else if (
      errors.password?.type === "specialCharacter" ||
      errors.newPassword?.type === "specialCharacter"
    ) {
      inputBorderColor = "#3B61EB";
    } else {
      inputBorderColor = "#C71313";
    }
  }
  if (props.name === "confirmPassword" && errors.confirmPassword) {
    inputBorderColor = "#C71313";
  }

  return (
    <Box className="password-input-wrapper" style={{ flexBasis: width }}>
      {" "}
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        defaultValue={defaultValue}
        render={({ field }) => (
          <input
            {...field}
            type={passwordIcon === "open" ? "text" : type}
            placeholder={placeHolder}
            className="password-input "
            style={{
              borderTopRightRadius: !withIcon ? "8px" : 0,
              borderBottomRightRadius: !withIcon ? "8px" : 0,
              borderColor: inputBorderColor,
            }}
            name={name}
            ref={ref}
            maxLength={16}
            {...rest}
          />
        )}
      />
      {passwordIcon === "close" && (
        <img
          src={eyeCloseIcon}
          alt="Close Icon"
          className="edit-icon"
          onClick={() => handleClick()}
          style={{ background: inputBorderColor }}
        />
      )}
      {passwordIcon === "open" && (
        <img
          src={eyeOpenIcon}
          alt="Open Icon"
          className="edit-icon"
          onClick={() => handleClick()}
          style={{ background: inputBorderColor }}
        />
      )}
      <p className="errorText" style={{ color: inputBorderColor }}>
        {errors[name]?.message as string}
      </p>
    </Box>
  );
});

export default PasswordInput;
