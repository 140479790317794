import "./login.scss";
import LoginForm from "./LoginForm";
import LoginOTPForm from "./LoginOTPForm";
import WelcomeSplash from "../../../components/ui/organism/WelcomeSplash";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../state/store";
import { fetchUser } from "../../../state/actions/userActions";

const Login = () => {
  const [logInSuccess, setLogInSuccess] = useState({
    email: "",
    status: false,
    appStatus: false,
    otpStatus: false,
  });
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const details = urlParams.get("details");

    if (details) {
      const decodedDetails = decodeURIComponent(details);
      const detailJSON = JSON.parse(decodedDetails);
      dispatch(fetchUser(detailJSON));
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (logInSuccess.appStatus) {
      timer = setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    }
    return () => {
      clearTimeout(timer as NodeJS.Timeout);
    };
  }, [logInSuccess.appStatus]);
  return (
    <Box className="loginContainer">
      {!logInSuccess.status && <LoginForm setLogInSuccess={setLogInSuccess} />}
      {logInSuccess.status && logInSuccess.otpStatus && (
        <LoginOTPForm
          emailAddress={logInSuccess.email}
          setLogInSuccess={setLogInSuccess}
        />
      )}
      {logInSuccess.appStatus && <WelcomeSplash />}
    </Box>
  );
};

export default Login;
