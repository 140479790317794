import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchUser,
  loginUser,
  logoutUser,
  verifyUser,
  updateUser,
} from "../../actions/userActions";

interface UserDataResult {
  result: UserData;
}

interface UserData {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileNumber: string;
  imageLocation?: string;
  token: string;
  applicationName:string;
  orgId:number;
}

interface UserState {
  userData: UserData | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: UserState = {
  userData: null,
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => {
      state.error = null;
      state.userData = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        loginUser.fulfilled,
        (state, action: PayloadAction<UserDataResult>) => {
          state.isLoading = false;
          state.userData = action?.payload?.result;
        }
      )
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = (action.payload as string) ?? "Login failed";
      })
      .addCase(logoutUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isLoading = false;
        state.userData = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? "Logout failed";
      })
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        verifyUser.fulfilled,
        (state, action: PayloadAction<UserDataResult>) => {
          state.isLoading = false;
          state.userData = action?.payload?.result;
        }
      )
      .addCase(verifyUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? "Signup failed";
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchUser.fulfilled,
        (state, action: PayloadAction<UserData>) => {
          state.isLoading = false;
          state.userData = action.payload;
        }
      )
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? "Fetch failed";
      })

      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? "Update failed";
      });
  },
});

export const { resetUser } = userSlice.actions;
export default userSlice.reducer;
