import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { apiClient } from "../../../../../config/apiClient";
import { ERROR_CODE, SUCCESS_CODE } from "../../../../../config/constant";
import { showErrorToast } from "../../../../../components/ui/atoms/Toast";
import { Box, Button } from "@chakra-ui/react";
import Input from "../../../../../components/ui/atoms/formElements/Input";
import PasswordInput from "../../../../../components/ui/atoms/formElements/PasswordInput";
import { colors } from "../../../../../theme/colors";

interface registerationSuccessProps {
  email: string;
  status: boolean;
}

interface setRegisterationSuccessProps {
  setRegisterationSuccess: React.Dispatch<
    React.SetStateAction<registerationSuccessProps>
  >;
}

const Form: React.FC<setRegisterationSuccessProps> = ({
  setRegisterationSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const methods = useForm();
  const { handleSubmit, watch } = methods;

  const onSubmit = async (data: any) => {
    console.log("data", data);
    setLoading(true);
    await createUser(data);
    setLoading(false);
  };

  // Validation rules
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

  const createUser = async (data: any) => {
    const { firstName, lastName, emailAddress, password, mobileNumber } = data;
    const payload = {
      firstName,
      lastName,
      emailAddress,
      password,
      mobileNumber,
      orgId: 1,
    };

    const resp = await apiClient.post(`/v1/user`, payload);
    const { data: respData } = resp;

    if (respData.statusCode === SUCCESS_CODE) {
      setRegisterationSuccess({ email: emailAddress, status: true });
    } else {
      if (respData.statusCode === ERROR_CODE) {
        showErrorToast("Error", respData.statusMessage);
      }
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          className="signUpForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Box className="signUpFormGroup">
            <Input
              name="firstName"
              rules={{
                required: {
                  value: true,
                  message: "First Name is required",
                },
              }}
              placeHolder="Enter First Name"
              defaultValue=""
              width={"46%"}
              withIcon
            />
            <Input
              name="lastName"
              rules={{
                required: {
                  value: true,
                  message: "Last Name is required",
                },
              }}
              placeHolder="Enter Last Name"
              defaultValue=""
              width={"46%"}
              withIcon
            />
          </Box>
          <Box className="signUpFormGroup">
            <Input
              name="emailAddress"
              rules={{
                required: {
                  value: true,
                  message: "Email is required",
                },
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid Email",
                },
              }}
              defaultValue=""
              placeHolder="Enter Email"
              withIcon
            />
          </Box>
          <Box className="signUpFormGroup">
            <Input
              name="mobileNumber"
              rules={{
                required: {
                  value: true,
                  message: "Mobile Number is required",
                },
                pattern: {
                  value: /^\d{1,10}$/,
                  message: "Please provide below 10 numbers",
                },
              }}
              defaultValue=""
              placeHolder="Enter Phone Number"
              withIcon
              type="number"
            />
          </Box>
          <Box className="signUpFormGroup">
            <PasswordInput
              name="password"
              rules={{
                required: {
                  value: true,
                  message: "Password is required",
                },
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
                validate: {
                  capitalLetter: (value: string) =>
                    /[A-Z]/.test(value) ||
                    "Must contain atleast 1 Capital letter",
                  specialCharacter: (value: string) =>
                    /[!@#$%^&*]/.test(value) ||
                    "Must contain atleast 1 special characters",
                },
              }}
              defaultValue=""
              type={"password"}
              placeHolder="Password"
              width={"48%"}
              withIcon
            />

            <PasswordInput
              name="confirmPassword"
              rules={{
                validate: (value: any) =>
                  value === watch("password") || "Password does not match",
              }}
              defaultValue=""
              type={"password"}
              placeHolder="Confirm Password"
              width={"48%"}
              withIcon
            />
          </Box>

          <Button
            width={"358px"}
            maxWidth={"358px"}
            background={colors.green}
            color={"white"}
            textAlign={"center"}
            fontSize={"14px"}
            fontWeight={"700"}
            lineHeight={"120%"}
            letterSpacing={"0.6px"}
            textTransform={"uppercase"}
            padding={"8px"}
            borderRadius={"4px"}
            boxShadow={"none"}
            border={"none"}
            _hover={{ backgroundColor: colors.green }}
            type="submit"
            style={{ margin: "34px 0 28px 0" }}
            disabled={loading}
          >
            SIGN UP
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default React.memo(Form);
