import airDrive from "../assets/images/dashboard/air-drive.svg";
import airVault from "../assets/images/dashboard/air-vault.svg";
import airScrapex from "../assets/images/dashboard/air-scrapex.svg";
import airProjects from "../assets/images/dashboard/air-projects.svg";
import airAnalytics from "../assets/images/dashboard/air-analytics.svg";
import airAtlas from "../assets/images/dashboard/air-atlas.svg";
import airErp from "../assets/images/dashboard/air-erp.svg";
import airHRM from "../assets/images/dashboard/air-hrm.svg";
import airProductCatalogue from "../assets/images/dashboard/air-product-catalogue.svg";

let orgArray: string[] = [];
export const getArr = (splitArr: string[]) => {
  orgArray = [...splitArr];
};

export const getDashboardApps = (token: string) => {
  const dashBoardData = [
    {
      appIcon: airVault,
      appName: "air-vault",
      _new: orgArray.includes("AIR_VAULT") ? false : true,
      headerTitle: "VAULT",
      headerSubTitle:
        "Get fully managed FTP and SFTP to securely manage cross-system file transfers with AIR Vault.",
    },
    {
      appIcon: airDrive,
      appName: "air-drive",
      _new: orgArray.includes("AIR_DRIVE") ? false : true,
      headerTitle: "DRIVE",
      headerSubTitle:
        "Manage your file storage anytime, from anywhere, with AIR Drive",
    },
    {
      appIcon: airScrapex,
      appName: "air-as2",
      _new: orgArray.includes("AIR_AS2") ? false : true,
      headerTitle: "AS2",
      headerSubTitle:
        "AS2, or Applicability Statement 2, is a widely used protocol in electronic data interchange (EDI) for secure and reliable data transmission over the Internet.",
    },
    {
      appIcon: airAnalytics,
      appName: "air-analytics",
      _new: orgArray.includes("AIR_ANALYTICS") ? false : true,
      headerTitle: "ANALYTICS",
      headerSubTitle:
        "Experience cutting-edge integration, accurate and actionable insights, and top-tier data security.",
    },
    // {
    //   appIcon: airScrapex,
    //   appName:'air-scrapex',
    //   _new: orgArray.includes("AIR_SCRAPEX") ? false : true,
    //   headerTitle: "SCRAPEX",
    //   headerSubTitle:
    //     "AIR Scrapex is how ethical, and efficient data extraction supports research, lead generation, and analysis.",
    // },
    {
      appIcon: airAtlas,
      appName: "air-atlas",
      _new: orgArray.includes("AIR_ATLAS") ? false : true,
      headerTitle: "ATLAS",
      headerSubTitle: "Experience transparent data integration with AIR Atlas.",
    },
    {
      appIcon: airProjects,
      appName: "air-projects",
      _new: orgArray.includes("AIR_PROJECTS") ? false : true,
      headerTitle: "PROJECTS",
      headerSubTitle:
        "AIR Projects is a project management tool designed to enhance team coordination, task management, and progress tracking",
    },
    {
      appIcon: airErp,
      appName: "air-erp",
      _new: orgArray.includes("AIR_ERP") ? false : true,
      headerTitle: "ERP LITE",
      headerSubTitle:
        "A finance tool with everything you need: billing, records, analytics and more",
    },
    {
      appIcon: airHRM,
      appName: "air-hrm",
      _new: orgArray.includes("AIR_HRM") ? false : true,
      headerTitle: "HRM",
      headerSubTitle:
        "Manage and simplify core Human Resource processes at every stage, from recruitment to offboarding.",
    },
    {
      appIcon: airProductCatalogue,
      appName: "air-product-catalogue",
      _new: orgArray.includes("AIR_PRODUCT-CATALOGUE") ? false : true,
      headerTitle: "PRODUCT CATALOGUE",
      headerSubTitle:
        "Access an invertory management system with easy-to-use controls and comprehensive visibility.",
    },
  ];

  return dashBoardData;
};
