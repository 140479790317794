import React, { useState } from "react";
import { Img, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logoSm from "../../../../assets/images/air_apps_logo_sm.svg";
import { colors } from "../../../../theme/colors";
import { ROUTER_ENUM } from "../../../../model/Router.enum";

interface SidebarProps {
  navSize: string;
}

const SideBar:React.FC<SidebarProps> =  ({navSize})=>{

  const navigate = useNavigate();

  return (
    <Box background={colors.lightGrey} w={navSize == "small" ? "72px" : "200px"}>
      <Box onClick={() => navigate(ROUTER_ENUM.DASHBOARD)} w={"72px"}>
        <Img
          cursor={"pointer"}
          src={logoSm}
          alt="logosm"
          w={"30px"}
          h={"30px"}
          marginLeft={"auto"}
          marginRight={"auto"}
          marginTop={"25px"}
        />
      </Box>
    </Box>
  );
}
export default React.memo(SideBar);