import { toast } from "react-toastify";
import ErrorToast from "./ErrorToast";
import SuccessToast from "./SuccessToast";

export const showSuccessToast = (title: string, message: string) => {
  toast(<SuccessToast title={title} message={message} />);
};

export const showErrorToast = (title: string, message: string) => {
  toast(<ErrorToast title={title} message={message} />);
};
