import React from "react";
import {
  Flex,
  Grid,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  useDisclosure,
  GridItem,
  Textarea,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import Header from "../../../components/ui/organism/Header";
import Sidebar from "../../../components/ui/organism/Sidebar";
import AppCard from "../../../components/modules/AppCard";
import { useEffect, useState } from "react";
import { RootState } from "../../../state/reducers";
import { useSelector } from "react-redux";
import Footer from "../../../components/ui/organism/Footer/footer";
import { getDashboardApps } from "../../../datas/data";
import DashModal from "../../../components/modules/dashModal/dashModal";
import { dashModelApi } from "../../../api/appServices/AppServices";
import { DashModalBodyReqState } from "../../../model/DashModal.interface";
import { appsData } from "../../../model/Dashboard.interface";
import { getArr } from "../../../datas/data";

const DashBoard = () => {
  const [navSize, changeNavSize] = useState<string>("small");
  const [isDashModalOpen, setIsDashModalOpen] = useState<boolean>(false);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [textMessage, setTextMessage] = useState<string>("");

  let [appDetails, setAppDetails] = useState<appsData>();

  const user = useSelector((state: RootState) => state.user.userData);
  const { token } = user ?? {};

  const splitArray: string[] =
    user?.applicationName != null ? user?.applicationName.split(",") ?? [] : [];
  getArr(splitArray);

  const serializedData = encodeURIComponent(JSON.stringify(user));

  const handleSubmit = (item: appsData) => {
    setAppDetails(item);
    if (!item._new) {
      if (token) {
        if (item.appName == "air-as2") {
          window.open(
            `${process.env.REACT_APP_APPLINKS_URL}/${item.appName}?uName=cintap&pWord=cintap@123&url=http://54.210.150.142:8090/api&appDetails=${serializedData}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APPLINKS_URL}/${item.appName}?token=${token}&appDetails=${serializedData}`,
            "_blank"
          );
        }
      }
    } else {
      setIsDashModalOpen(true);
    }
  };

  const HandleClose = () => {
    setIsDashModalOpen(false);
  };

  const handleSubmitRequest = async (textMessage: string) => {
    // call the API
    const body = {
      applicationRequest: {
        userId: user?.id,
        applicationNames: appDetails?.appName,
        comments: textMessage,
      },
      user: {
        id: user?.id,
        orgId: user?.orgId,
        emailAddress: user?.emailAddress,
        firstName: user?.firstName,
        lastName: user?.lastName,
        mobileNumber: user?.mobileNumber,
      },
    } as DashModalBodyReqState;
    const response = await dashModelApi(body, token ?? "");
    if (response?.statusCode == "0000") {
      toast.info(
        "Thanks for the new application request, our sales team will get in touch with you",
        { position: "top-center", theme: "colored", autoClose: 5000 }
      );
    } else {
      toast.error("Already Requested for this Application.", {
        position: "top-center",
        theme: "colored",
        autoClose: 5000,
      });
    }
    setIsDashModalOpen(false);
  };

  return (
    <Box
      backgroundColor={"white"}
      w="100%"
      h="100%"
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box display={"flex"} h={"100%"} overflow={"hidden"}>
        <Sidebar navSize={navSize} />

        <Box
          height={"100%"}
          width={"100%"}
          overflow={"hidden"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Header headerTitle={""} />

          <Box
            paddingTop={"50px"}
            paddingLeft={"50px"}
            paddingRight={"50px"}
            gap={"18px"}
            height={"100%"}
            display={"flex"}
            overflow={"auto"}
            flexDirection={"column"}
          >
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={6}
            >
              {getDashboardApps(token ?? "").map((item, index) => {
                const { appIcon, headerTitle, headerSubTitle, _new } = item;
                return (
                  <AppCard
                    _new={_new}
                    key={index}
                    appIcon={appIcon}
                    headerTitle={headerTitle}
                    headerSubTitle={headerSubTitle}
                    handleSubmit={() => handleSubmit(item)}
                  />
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
      <DashModal
        handleSubmit={handleSubmitRequest}
        handleClose={HandleClose}
        isOpen={isDashModalOpen}
      />
    </Box>
  );
};

export default React.memo(DashBoard);
