import { Box } from "@chakra-ui/react";
import "./formFooter.css";

const FormFooter = ({ footerTitle = "", linkText = "", handleClick }: any) => {
  return (
    <>
      <Box className="footerTitleText">{footerTitle}</Box>
      <Box className="footerLink" onClick={handleClick}>
        {linkText}
      </Box>
    </>
  );
};

export default FormFooter;
