import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ProfileComponent from "../../../components/modules/ProfileComponents";
import Header from "../../../components/ui/organism/Header";
import Sidebar from "../../../components/ui/organism/Sidebar";
import Footer from "../../../components/ui/organism/Footer/footer";

const Profile = () => {
  const [navSize, changeNavSize] = useState("small");

  return (
    <Box
      backgroundColor={"white"}
      w="100%"
      h="100%"
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box display={"flex"} h={"100%"} overflow={"hidden"}>
        <Sidebar navSize={navSize} />
        <Box
          height={"100%"}
          width={"100%"}
          overflow={"hidden"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Header headerTitle={"Settings"} />
          <Box
            height={"100%"}
            display={"flex"}
            overflow={"auto"}
            flexDirection={"column"}
          >
            <ProfileComponent />
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default React.memo(Profile);
