import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchUserAPI,
  loginUserAPI,
  logoutUserAPI,
  verifyUserAPI,
  updateUserAPI,
} from "../../../api/user"; // Assuming you have an API file for user login

interface LoginCredentials {
  emailAddress: string;
  password: string;
}

interface VerifyOtpData {
  emailAddress: string;
  otp: string;
}

interface UpdateUserData {
  firstName: string;
  lastName: string;
  mobileNumber: string;
}

interface UserData {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  imageLocation?: string;
  mobileNumber: string;
  token: string;
}

interface UpdatePayload {
  user: UserData;
  updateUserData: UpdateUserData;
}

export const loginUser = createAsyncThunk(
  "user/login",
  async (credentials: LoginCredentials, { rejectWithValue }) => {
    try {
      const response = await loginUserAPI(credentials);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      await logoutUserAPI();
      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyUser = createAsyncThunk(
  "user/signup",
  async (data: VerifyOtpData, { rejectWithValue }) => {
    try {
      const response = await verifyUserAPI(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (data: UpdatePayload, { rejectWithValue }) => {
    try {
      const resData = await updateUserAPI(data);
      return resData;
      // Fetch updated user data after update
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetch",
  async (user: UserData, { rejectWithValue }) => {
    try {
      const response = await fetchUserAPI(user);
      return { ...response?.result, token: user?.token };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
