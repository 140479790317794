import FormHeader from "../../../../components/ui/organism/FormHeader";
import FormFooter from "../../../../components/ui/organism/FormFooter";
import FormContainer from "../../../../components/ui/organism/FormContainer";
import "./loginOTPForm.scss";
import OTPForm from "../../../../components/modules/OTPForm";
import { apiClient } from "../../../../config/apiClient";
import { useNavigate } from "react-router-dom";
import { SUCCESS_CODE, VERIFY_OTP_API_URL } from "../../../../config/constant";
import React from "react";
import { ROUTER_ENUM } from "../../../../model/Router.enum";

interface logInSuccessProps {
  email: string;
  status: boolean;
  appStatus: boolean;
  otpStatus: boolean;
}
interface LoginOTPFormProps {
  emailAddress: string;
  setLogInSuccess: React.Dispatch<React.SetStateAction<logInSuccessProps>>;
}

const LoginOTPForm: React.FC<LoginOTPFormProps> = ({
  emailAddress,
  setLogInSuccess,
}) => {
  
  const navigate = useNavigate();

  const handleOtpSubmit = async (otp: String) => {
    const payload = {
      emailAddress,
      otp,
    };

    const resp = await apiClient.post(VERIFY_OTP_API_URL, payload);
    const { data: respData } = resp;
    if (respData.statusCode === SUCCESS_CODE) {
      setLogInSuccess((prev: any) => ({
        ...prev,
        email: emailAddress,
        appStatus: true,
      }));
    }
  };

  return (
    <FormContainer>
      <FormHeader
        subHeaderText={`We have sent you an  OTP to <span class="loginEmailText">&lt;${emailAddress} with first 3 digits&gt;</span>`}
      />
      <OTPForm inputLength={6} onSubmit={handleOtpSubmit} />
      <FormFooter
        footerTitle="Don't have an account?"
        linkText="SIGN UP NOW"
        handleClick={() => navigate(ROUTER_ENUM.SIGN_UP)}
      />
    </FormContainer>
  );
};

export default React.memo(LoginOTPForm);
