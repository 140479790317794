// import { toast } from 'react-hot-toast';
import "./success.scss";
import { Box, Flex } from "@chakra-ui/react";
const SuccessToast: React.FC<{ title: string; message: string }> = ({
  title,
  message,
}) => {
  const dismissToast = () => {
    // toast.dismiss();
  };

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      bg={"#2c8526"}
      padding={"6px 6px 6px 10px"}
      borderRadius={"18px 0px 0px 18px"}
    >
      <Flex marginRight={"18px"}>
        <svg
          width="76"
          height="76"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 12.3333L10.4615 15L16 9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Flex>
      <Flex
        marginRight={"18px"}
        justifyContent="flex-start"
        flexDirection={"column"}
      >
        <Box className="successTitle">{title}</Box>
        <Box className="sucessSubtitle">{message}</Box>
      </Flex>
      <Flex position={"relative"}>
        <button className="crossButton" onClick={dismissToast}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 6L18 18M18 6L6 18"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </Flex>
    </Flex>
  );
};

export default SuccessToast;
