import "./error.scss";
import { Box, Flex } from "@chakra-ui/react";
const ErrorToast: React.FC<{ title: string; message: string }> = ({
  title,
  message,
}) => {
  const dismissToast = () => {
    // toast.dismiss();
  };

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      bg={"#B90D2C"}
      padding={"6px 6px 6px 10px"}
      borderRadius={"18px 0px 0px 18px"}
    >
      <Flex marginRight={"18px"}>
        <svg
          width="76"
          height="76"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9997 17.0003H12.0097M11.9997 10.0003V14.0003M6.41173 21.0003H17.5876C19.3692 21.0003 20.2601 21.0003 20.7826 20.6256C21.2385 20.2987 21.5362 19.7953 21.6029 19.2382C21.6794 18.5999 21.2501 17.8193 20.3915 16.2582L14.8035 6.0983C13.8894 4.43614 13.4323 3.60507 12.8283 3.33012C12.3019 3.09048 11.6975 3.09048 11.171 3.33012C10.567 3.60507 10.11 4.43614 9.19577 6.09829L3.60784 16.2582C2.74922 17.8193 2.31991 18.5999 2.3964 19.2382C2.46315 19.7953 2.7608 20.2987 3.21673 20.6256C3.73924 21.0003 4.63007 21.0003 6.41173 21.0003Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Flex>
      <Flex
        marginRight={"18px"}
        justifyContent="flex-start"
        flexDirection={"column"}
      >
        <Box className="successTitle">{title}</Box>
        <Box className="sucessSubtitle">{message}</Box>
      </Flex>
      <Flex position={"relative"}>
        <button className="crossButton" onClick={dismissToast}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 6L18 18M18 6L6 18"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </Flex>
    </Flex>
  );
};

export default ErrorToast;
