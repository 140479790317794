import React, { useState } from "react";
import FormHeader from "../../../../components/ui/organism/FormHeader";
import FormFooter from "../../../../components/ui/organism/FormFooter";
import FormContainer from "../../../../components/ui/organism/FormContainer";
import "./signUpForm.css";
import { useNavigate } from "react-router-dom";
import Form from "../SignUpForm/FormSignUp/index";

interface registerationSuccessProps {
  email: string;
  status: boolean;
}

interface setRegisterationSuccessProps {
  setRegisterationSuccess: React.Dispatch<
    React.SetStateAction<registerationSuccessProps>
  >;
}

const SignUpForm: React.FC<setRegisterationSuccessProps> = ({
  setRegisterationSuccess,
}) => {
  const navigate = useNavigate();
  return (
    <FormContainer>
      <FormHeader
        headerTypeText="Sign up to"
        subHeaderText="Welcome please enter your details"
      />
      <Form setRegisterationSuccess={setRegisterationSuccess} />
      <FormFooter
        footerTitle="Already have an account?"
        linkText="SIGN IN NOW"
        handleClick={() => navigate("/login")}
      />
    </FormContainer>
  );
};

export default React.memo(SignUpForm);
