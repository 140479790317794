// ProtectedComponent.js
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../../state/reducers";

interface ProtectedComponentProps {
  children: React.ReactNode;
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  children,
}) => {
  const userData = useSelector((state: RootState) => state.user.userData);

  if (!userData || !userData?.id) {
    // If user is not logged in, redirect to login page
    return <Navigate to="/login" replace={true} />;
  }

  // If user is logged in, render the component
  return <>{children}</>;
};

export default ProtectedComponent;

// // ProtectedRoute.tsx
// import React from "react";
// import { Route, Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../state/reducers";

// interface ProtectedRouteProps {
//   path: string;
//   element: React.ReactNode; // Use React.ReactNode here
// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ path, element }) => {
//   const userData = useSelector((state: RootState) => state.user.userData);

//   return userData && userData?.id ? (
//     <Route path={path} element={element} />
//   ) : (
//     <Navigate to="/login" replace={true} />
//   );
// };

// export default ProtectedRoute;
// // ProtectedRoute.tsx
// import React from "react";
// import { Route, Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../state/reducers";

// interface ProtectedRouteProps {
//   Component: JSX.Element;
// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ Component }) => {
//   const userData = useSelector((state: RootState) => state.user.userData);

//   return userData && userData?.id ? (
//     <Component />
//   ) : (
//     <Navigate to="/login" replace={true} />
//   );
// };

// export default ProtectedRoute;
