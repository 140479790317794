import { Box } from "@chakra-ui/react";
import logo from "../../../../assets/images/Logo.png";
import "./formHeader.css";

const FormHeader = ({ headerTypeText = "", subHeaderText }: any) => {
  return (
    <Box className="formHeader">
      <img src={logo} width={"72px"} height={"60px"} />
      <span className="formHeaderText">
        {headerTypeText} <b>AIR</b> APPS
      </span>
      <span
        className="formSubHeaderText"
        dangerouslySetInnerHTML={{ __html: subHeaderText }}
      />
    </Box>
  );
};

export default FormHeader;
