import FormContainer from "../FormContainer";
import FormHeader from "../FormHeader";
import checkCircle from "../../../../assets/images/check-circle.png";
import { Box } from "@chakra-ui/react";

const WelcomeSplash = () => {
  return (
    <FormContainer>
      <FormHeader subHeaderText="welcome" />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "332px",
        }}
      >
        <img src={checkCircle} width={"162px"} height={"162px"} />
      </Box>
    </FormContainer>
  );
};

export default WelcomeSplash;
