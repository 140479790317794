import { forwardRef, useRef } from "react";
import "./input.scss";
import editIcon from "../../../../../assets/icons/editIcon.svg";
import { Controller, useFormContext } from "react-hook-form";
import { text } from "stream/consumers";
import { Box } from "@chakra-ui/react";
import React from "react";

const CustomInput = forwardRef((props: any, ref) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    type = "text",
    placeHolder = "",
    width = "100%",
    withIcon,
    icon = editIcon,
    name,
    defaultValue,
    rules,
    ...rest
  } = props;

  const inputRef = useRef<HTMLInputElement>();
  const handleIconClick = () => {
    inputRef?.current?.focus();
  };

  function capitalizeFirstLetter(firstOne: string) {
    return firstOne?.charAt(0).toUpperCase() + firstOne?.slice(1);
  }

  return (
    <Box className="custom-input-wrapper" style={{ flexBasis: width }}>
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        defaultValue={defaultValue}
        render={({ field }) => (
          <input
            {...field}
            type={type}
            placeholder={placeHolder}
            className="custom-input"
            style={{
              borderTopRightRadius: !withIcon ? "8px" : 0,
              borderBottomRightRadius: !withIcon ? "8px" : 0,
            }}
            name={name}
            ref={inputRef}
            allowNumberOnly={true}
            {...rest}
            value={type === "text" ? capitalizeFirstLetter(field.value) : field.value}
          />
        )}
      />
      {withIcon && (
        <img
          src={icon}
          alt="Edit Icon"
          className="edit-icon"
          onClick={handleIconClick}
        />
      )}
      <p className="errorText">{errors[name]?.message as string}</p>
    </Box>
  );
});

export default React.memo(CustomInput);
