import "./App.css";
import SignUp from "../src/pages/regular/SignUp";
import Login from "./pages/regular/Login";
import { Route, Routes } from "react-router-dom";
import ForgetPassword from "./pages/regular/ForgetPassword";
import DashBoard from "./pages/protected/DashBoard/index";
import Profile from "./pages/protected/Profile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/modules/ProtectedRoute";
import { Box } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Box className="App" width={"100%"} height={"100%"} overflow={"hidden"}>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
          className="Toastify__toast--slideIn"
        />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashBoard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Box>
    </QueryClientProvider>
  );
}

export default App;
